<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="mt-2">
      Buscar correo
      <template v-slot:actions>
        <v-icon
          color="primary"
          @click.native.stop="reloadp++"
        >
          mdi-refresh
        </v-icon>
        <v-icon
          color="error"
          @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })"
        >
          mdi-close
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :key="reloadp">
      <v-card>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#tab-1">
            Buscar correo
            <v-icon>mdi-email-search</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="tab-1">
            <v-card flat>
              <v-card-text>
                <v-card>
                  <!-- Needs filter -->
                  <DxDataGrid class="isScrolledByGeneralFilter"
                    :data-source="emails"
                    key-expr="Email_Id"
                    :show-row-lines="showRowLines"
                    :show-borders="showBorders"
                    :row-alternation-enabled="rowAlternationEnabled"
                  >
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxPaging :page-size="10" />
                    <DxColumn
                      :width="80"
                      cell-template="show-template"
                      caption=""
                    ></DxColumn>
                    <DxColumn data-field="Email_Id" caption="ID"></DxColumn>
                    <DxColumn data-field="EmailName" caption="Nombre del correo"></DxColumn>
                    <DxColumn
                      data-field="Email_Body"
                      caption="Cuerpo del correo"
                      cell-template="email-body"
                    />
                    <template #email-body="{ data }">
                      <v-icon small class="mr-2" @click="showEmail(data.data)"
                        color="primary"
                        >mdi-eye</v-icon
                      >
                    </template>
                    <DxColumn data-field="ID_User" caption="ID usuario"></DxColumn>
                    <DxColumn data-field="State" caption="Estado" cell-template="state" />
                    <template #state="{ data }">
                      <v-chip
                        small
                        :color="
                          data.data.State === 'Active' || data.data.State === 1 || data.data.State === '1' ? 'success' : 'grey'
                        "
                        >{{
                          data.data.State === "Active" || data.data.State === 1 || data.data.State === "1" ? "Active" : "Inactive"
                        }}</v-chip
                      >
                    </template>
                    <DxColumn
                      data-field="SystemDateInsert"
                      caption="Fecha de creación"
                      cell-template="created-at"
                    />
                    <template #created-at="{ data }">
                      <span>
                        {{
                          moment(data.data.SystemDateInsert).format("DD MMM YYYY, h:mm A")
                        }}
                      </span>
                    </template>

                    <DxColumn
                      data-field="SystemDateUpdate"
                      caption="Fecha de actualización"
                      cell-template="updated-at"
                    />
                    <template #updated-at="{ data }">
                      {{
                        moment(data.data.SystemDateUpdate).format("DD MMM YYYY, h:mm A")
                      }}
                    </template>
                    <template #show-template="{ data }">
                      <div>
                        <a href="javascript:void(0)" @click="emailSelected(data)">
                          <v-icon small class="mr-2" color="primary">mdi-eye</v-icon>
                        </a>
                        <!-- <a
                          href="javascript:void(0)"
                          @click.prevent="
                            emailToDelete = data.data;
                            deleteDialog = true;
                          "
                        >
                          <v-icon small color="error">mdi-delete</v-icon>
                        </a> -->
                      </div>
                    </template>
                  </DxDataGrid>
                </v-card>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-expansion-panel-content>

    <show-email-dialog
      ref="showDialog"
      :data="emailToShow"
      @close="closeShowDialog"
    ></show-email-dialog>

    <v-dialog v-model="deleteDialog" max-width="290">
      <delete-email-dialog
        :data="emailToDelete"
        @close="closeDialog"
      ></delete-email-dialog>
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import { DxDataGrid, DxColumn, DxScrolling, DxPaging } from "devextreme-vue/data-grid";
import DeleteEmailDialog from "./DeleteEmailDialog";
import ShowEmailDialog from "./ShowEmailDialog";
import { mapGetters } from "vuex";
export default {
  name: "EmailList",
  components: {
    DeleteEmailDialog,
    ShowEmailDialog,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxPaging,
  },
  props: ["win"],
  data() {
    return {
      reloadp: 0,
      search: "",
      tab: null,
      emails: [],
      deleteDialog: false,
      emailToDelete: null,
      emailToShow: {
        Email_Body: "",
      },
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
    };
  },
  mounted() {
    this.getAllEmails();
  },
  computed: {
    ...mapGetters(["reloadData"]),
  },
  methods: {
    getAllEmails() {
      this.$API.email.getEmails().then((response) => {
        this.emails = response;
      });
    },
    showEmail(data) {
      this.emailToShow = Object.assign({}, data);
      this.$refs.showDialog.showDialog = true;
    },
    closeShowDialog() {
      this.emailToShow = Object.assign({}, { Email_Body: "" });
      this.$refs.showDialog.showDialog = false;
    },
    closeDialog(payload) {
      this.emailToDelete = null;
      this.deleteDialog = false;
      if (payload.isDeleted) {
        this.getAllEmails();
      }
    },

    emailSelected(data) {
      this.$store.dispatch("addWindow", {
        name: "EmailData",
        component: "EmailData",
        unique: false,
        meta: { email: data.data },
      });
    },
  },
};
</script>

<style scoped></style>
